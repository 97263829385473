<template>
  <div style="width: 100%">
    <el-carousel
      :interval="5000"
      arrow="always"
      style="height: 780px"
      indicator-position="outside"
      height="750px"
    >
      <el-carousel-item
        v-for="url in imagesUrls"
        :key="url"
        style="height: 780px"
      >
        <el-image :src="url" class="image"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
  <div class="w-title-inner">
    <h2>产品中心</h2>
    <div class="w-title-inner-span">
      <span
        >公司以软件开发、技术推广、技术咨询、支付系统为主、POS机收单业务为辅的网络科技公司。针对不同的客户群体，将以专业化的技术服务团队为客户提供最满意的解决方案，并以“优质、贴心、快速、高效”的服务理念全心全意为客户提供技术支持及售后服务。</span
      >
    </div>
  </div>
  <div class="module-div">
    <el-row :gutter="20">
      <el-col
        :span="5"
        v-for="data in productDataOne"
        :key="data"
        style="margin-right: 20px"
      >
        <el-card>
          <div class="grid-content bg-purple">
            <img
              :src="data.img"
              class="image"
              @click="toProductDetailsOne(data)"
            />
          </div>
          <div style="padding: 14px">
            <span>{{ data.name }}</span>
          </div>
          <div>
            <span class="product-describe">{{ data.describe }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 50px">
      <el-col
        :span="5"
        v-for="data in productDataTwo"
        :key="data"
        style="margin-right: 20px"
      >
        <el-card>
          <div class="grid-content bg-purple">
            <img
              :src="data.img"
              class="image"
              @click="toProductDetailsTwo(data)"
            />
          </div>
          <div style="padding: 14px">
            <span>{{ data.name }}</span>
          </div>
          <div>
            <span class="product-describe">{{ data.describe }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>

  <div class="w-title-inner">
    <h2>服务体系</h2>
    <div>
      <span
        >公司产品畅立收，为全国线上、线下商户提供安全、专业、便捷的收单软硬件产品。刷脸支付商用元年，刷脸支付AI新科技，打造高效、智能化收银管理平台，引领支付行业新方向。</span
      >
    </div>
  </div>

  <div class="service">
    <el-row :gutter="20" style="margin-top: 50px">
      <el-col
        :span="4"
        v-for="data in serviceData"
        :key="data"
        style="margin-left: 50px; margin-top: 80px"
      >
        <el-card>
          <div class="grid-content bg-purple">
            <img
              :src="data.img"
              class="image"
              @click="toServiceDetails(data)"
            />
          </div>
          <div class="service-title">
            <span>{{ data.name }}</span>
            <p class="service-p"></p>
          </div>

          <div style="margin-top: -14px; font-size: 13px">
            <span class="service-describe">{{ data.describe }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
  <div class="w-title-inner">
    <h2>新闻中心</h2>
    <div>
      <span
        >公司产品畅立收，为全国线上、线下商户提供安全、专业、便捷的收单软硬件产品。刷脸支付商用元年，刷脸支付AI新科技，打造高效、智能化收银管理平台，引领支付行业新方向。</span
      >
    </div>
  </div>

  <div class="module-div">
    <el-row :gutter="20">
      <el-col :span="9" style="margin-top: 80px">
        <div>
          <el-carousel :interval="5000" style="height: 390px"  height="390px">
            <el-carousel-item
              v-for="data in newsData"
              :key="data"
              style="height: 390px"
              indicator-position="outside"
             
            >
              <span><el-image :src="data.img" class="imge"></el-image></span>
              <span style="padding-top: 80px">{{ data.name }}</span>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-col>
      <el-col :span="10" style="margin-top: 80px; margin-left: 100px">
        <div
          class="news-item"
          v-for="data in newsDataCom"
          :key="data"
          @mouseover="addActive($event)"
          @mouseout="removeActive($event)"
        >
          <div style="margin-bottom: 10px">
            <span class="news-date">{{ data.date }}</span>
            <el-link
              style="font-size: 16px; color: #000"
              @click="toNewDetails(data)"
              >{{ data.title }}</el-link
            >
          </div>
          <div>
            <span class="news-year">{{ data.year }}</span>
            <label class="news-describe">{{ data.describe }}</label>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <div class="module-div">
    <el-row :gutter="20">
      <el-col :span="9" style="margin-top: 80px">
        <h2 style="text-align: left">关于我们</h2>
        <p class="we-p"></p>
        <div class="we-div">
          畅立收--高效收银、便捷支付山东现代星驿商贸有限公司投资创建于2018年。是一家以软件开发、技术推广、技术咨询、支付系统为主、POS机收单业务为辅的网络科技公司。针对不同的客户群体，将以专业化的技术服务团队为客户提供最满意的解决方案，并以“优质、贴心、快速、高效”的服务理念全心全意为客户提供技术支持及售后服务。公司立足于山东菏泽，业务范围辐射全国各地。公司产品畅立收，为全国线上、线下商户提供安全、专业、便捷的收单软硬件产品。畅立收—让支付更简单刷脸支付商用元年，刷脸支付AI新科技，打造高效、智能化收银管理平台，引领支付行业新方向。新支付、新科技、新零售
        </div>
        <div style="margin-top: 100px">
          <el-button type="primary" size="medium" @click="toAbout"
            >查看详情</el-button
          >
        </div>
      </el-col>
      <el-col :span="13" style="margin-top: 80px">
        <div>
          <img src="../assets/image/we/we-1.jpg" />
        </div>
      </el-col>
    </el-row>
  </div>
  <div class="w-title-inner">
    <h2>合作伙伴</h2>
  </div>
  <div class="module-div">
    <el-row :gutter="20">
      <div
        style="width: 280px; height: 200px; margin-left: -10px"
        v-for="data in cooperationDataOne"
        :key="data"
        class="bg-purple"
      >
        <img :src="data" class="image" />
      </div>
    </el-row>
    <el-row :gutter="20">
      <div
        style="width: 280px; height: 200px; margin-left: -10px"
        v-for="data in cooperationDataTwo"
        :key="data"
        class="bg-purple"
      >
        <img :src="data" class="image" />
      </div>
    </el-row>
  </div>
</template>

<script>
import carousel_1 from "@/assets/image/home/carousel-1.jpg";
import carousel_2 from "@/assets/image/home/carousel-2.jpg";
import carousel_3 from "@/assets/image/home/carousel-3.png";
import qwpro from "@/assets/image/product-center/qwpro.png";
import qt from "@/assets/image/product-center/qt.png";
import smD2 from "@/assets/image/product-center/smD2.png";
import jhrwm from "@/assets/image/product-center/jhrwm.jpg";
import smzfzd from "@/assets/image/product-center/smzfzd.png";
import xbh from "@/assets/image/product-center/xbh.png";
import pay from "@/assets/image/service-system/pay.jpg";
import sczc from "@/assets/image/service-system/sczc.jpg";
import news_1 from "@/assets/image/news/news-1.jpg";
import news_4 from "@/assets/image/news/news-4.jpg";
import hz_1 from "@/assets/image/cooperation/hz-zfb.png";
import hz_2 from "@/assets/image/cooperation/hz-wx.png";
import hz_3 from "@/assets/image/cooperation/hz-jd.png";
import hz_4 from "@/assets/image/cooperation/hz-xdkf.png";
import hz_5 from "@/assets/image/cooperation/hz-xtx.png";
import hz_6 from "@/assets/image/cooperation/hz-xlk.png";
import hz_7 from "@/assets/image/cooperation/hz-fy.jpg";
import hz_8 from "@/assets/image/cooperation/hz-hfg.jpg";
import hz_9 from "@/assets/image/cooperation/hz-hrt.jpg";
import hz_10 from "@/assets/image/cooperation/hz-wsyh.jpg";
import xtkh from "@/assets/image/technology/technology-1.png";
import pay_pass from "@/assets/image/service-system/pay-pass.jpg";
import online_system from "@/assets/image/service-system/online-system.jpg";
import market_support from "@/assets/image/service-system/market-support.jpg";
export default {
  name: "Index",
  components: {
    // HelloWorld,
  },

  data() {
    return {
      cooperationDataOne: [hz_1, hz_2, hz_3, hz_4, hz_5],
      cooperationDataTwo: [hz_6, hz_7, hz_8, hz_9, hz_10],
      imagesUrls: [carousel_1, carousel_2, carousel_3],
      productDataOne: [
        {
          img: qwpro,
          name: "青蛙pro",
          date: "2021-09-16",
          describe:
            "双面屏，全面替代收银机，接入微信会员系统，打造智慧经营生态闭环。具有即插即用的特点，无需安装开发插件，轻松实现微信刷脸支付功能，同时也支持扫码支付。",
        },
        {
          img: qt,
          name: "支付宝-蜻蜓",
          date: "2021-09-16",
          describe:
            "1.POS模式:利用智能插件，无缝对接商户现有收银系统。2.独立模式:开发版蜻蜓，无需对接收银机，按键收款，有流水分润。",
        },
        {
          img: smD2,
          name: "商米D2",
          date: "2021-09-16",
          describe:
            "双面屏收银机，自带支付宝刷脸摄像头,三步解决收银问题，实现“又快、又准”收银。商品信息精准录入管理、销售，自动生成码，商品销售、采购、盘点无误。",
        },
        {
          img: jhrwm,
          name: "聚合二维码",
          date: "2021-09-16",
          describe:
            "融合支付宝、微信、京东、银联等主流支付为一体。方便快捷，提高商户日常收银、查账、对账效率，节省收银台空间。",
        },
      ],
      productDataTwo: [
        {
          img: smzfzd,
          name: "扫码支付终端",
          date: "2021-09-16",
          describe:
            "小巧时尚功能多样，可靠性强，是一款集扫码与非接于一体的新兴支付产品。可搭配ECR、POS终端、自动售卖机或电子秤等设备使用，也可以独立使用，满足各种非接和扫码支付。",
        },
        {
          img: xbh,
          name: "小白盒",
          date: "2021-09-16",
          describe:
            "智慧收银利器，解决二维码支付问题，即插即用。快速对接收银台、智能收银机、电脑和平板电脑，兼容性强，不限操作系统。",
        },
        {
          img: qt,
          name: "支付宝-蜻蜓",
          date: "2021-09-16",
          describe:
            "1.POS模式:利用智能插件，无缝对接商户现有收银系统。2.独立模式:开发版蜻蜓，无需对接收银机，按键收款，有流水分润。",
        },
        {
          img: qwpro,
          name: "青蛙pro",
          date: "2021-09-16",
          describe:
            "双面屏，全面替代收银机，接入微信会员系统，打造智慧经营生态闭环。具有即插即用的特点，无需安装开发插件，轻松实现微信刷脸支付功能，同时也支持扫码支付。",
        },
      ],
      serviceData: [
        {
          img: smD2,
          name: "系统开户流程",
          date: "2021-09-16",
          describe:
            "刷脸支付刷脸支付商用元年，刷脸支付AI新科技，打造高效、智能化收银管理平台，引领支付行业新方向。新支付、新科技、新零售、新未来，抓住趋势用智慧营造赋能未来。",
          detailsImg: xtkh,
        },
        {
          img: pay,
          name: "支付方式/支付通道",
          describe:
            "2019年，产业迎来新革命，支付4.0时代正式开启，未来10年都是刷脸支付的时代。创造多样化线下商户智能收银场景，提升收银效率",
          detailsImg: pay_pass,
        },
        {
          img: qt,
          name: "线上系统",
          date: "2021-09-16",
          describe:
            "一直专注于多生物特征识别的核心技术、公安信息化综合应用、视频侦查技术和大数据综合应用等四大领域，利用人工智能实现“人”的发现、寻找、检验、认定，为公安打击犯罪，及公共安全防范提供综合解决方案。针对现场...",
          detailsImg: online_system,
        },
        {
          img: sczc,
          name: "市场支持",
          date: "2021-09-16",
          describe:
            "提供畅立收科技产品的活动政策,针对现场勘验工作相关的信息采集、信息处理、资料存储、卷宗制作、检验鉴定、物证保管、案件串并及信息发布等重要环节,刑事技术整体解决方案,人员信息综合采集,人脸识别远程监控报警系统",
          detailsImg: market_support,
        },
        {
          img: pay,
          name: "客服支持",
          date: "2021-09-16",
          describe:
            "72小时的技术支持,为全国合作商提供行业解决方案支持,针对现场勘验工作相关的信息采集、信息处理、资料存储、卷宗制作、检验鉴定、物证保管、案件串并及信息发布等重要环节,刑事技术整体解决方案,人员信息综合采集,人脸识别远程监控报警系统",
          detailsImg: market_support,
        },
      ],
      newsData: [
        {
          img: qwpro,
          name: "畅立收—让支付更简单",
        },
        {
          img: smD2,
          name: "山东现代星驿商贸有限公司的建议",
        },
        {
          img: news_1,
          name: "央行：数字人民币主要用于国内零售支付，条件成熟时考虑跨境支付",
        },
      ],
      newsDataCom: [
        {
          year: "2021-09",
          date: "14",
          title: "畅立收—让支付更简单",
          describe:
            "随着建站的技术进步了，自助建站可以说是建站的一大法宝呀。而网站的加载速度是自助建站中一个非常重要的因素。提升网站的加载速度有三个方面：提升企业网站的加载速度有三个方面：提高用户自身的网络带宽、网站图片优化、站长对自己网站进行优化。今天我们站在站长的角度去分析一下网站优化的技巧。" +
            "靠前、图片优化的注意事项。基本上每一个网站都搭配有图片，早期的网络速度是非常慢的，如果用来浏览现在有大量图片的网站肯定很痛苦，会让很多人抓狂。加载一个大型的企业网站会花很长的时间。即使是在现在的带宽，图片的优化还是很重要的。图片的优化包括图片数量的控制、图片质量的把控以及图片格式的优化。" +
            "1、尽量减少图片的数量，不必要的图片就不要使用了。2、适当降低图片的质量，在制作网站过程中，没有必要的话，可以适当地降低图片的质量，特别是JPG格式的图片，将图片的质量降低5%，变化并不大，但是对网站的优化效果却很有帮助，因为图片文件的大小变化比较大。图片的格式设置要合适。上传图片之前，可以先编辑一下图片。",
          img: qwpro,
        },
        {
          year: "2021-09",
          date: "14",
          title: "网页制作的三个细节可以留住用户",
          describe:
            "网页制作的成功并不是表现在网站的流量上，而是网站有多少忠实用户，如果一个网站的忠实用户特别多，那么这个网站的建设就越成功。那么怎样才能赢得更多的忠实用户呢？下面我们就来分析是三个细节方面，希望可以帮助到各位站长。" +
            "靠前、网站广告的数量要控制好，不要设置弹窗广告。网站能不能留住靠前次访问网站的用户，就要看网站能够给用户提供一个舒适的浏览环境。那么，网站的广告一定不能设置太多，更加不要设置弹窗广告。因为用户对弹窗广告是非常反感的。" +
            "而高质量有价值的网站内容是吸引用户的法宝，也是首要条件，所以不要让广告遮住了网站的内容，从而失去用户。",
          img: news_4,
        },
        {
          year: "2021-09",
          date: "14",
          title: "山东现代星驿商贸有限公司的建议",
          describe:
            "搜索引擎优化我们也叫SEO优化，而且SEO优化在行业中已经被当作是重要的优化方法...",
          img: smD2,
        },
        {
          year: "2021-09",
          date: "14",
          title: "央行：数字人民币主要用于国内零售支付，条件成熟时考虑跨境支付",
          describe:
            "中国人民银行研究局局长王信今日在国新办举行的新闻发布会上表示，数字人民币将主要用于国内零售支付，此前已在国内多个试点地区进行测试，测试场景越来越丰富，也考虑在条件成熟时、顺应市场需求用于跨境支付交易，这也是可以实现的。" +
            "此外，他还表示，前期人民银行数字货币研究所与香港金融管理局就数字人民币在内地和香港地区的跨境使用进行了技术测试。人民银行数字货币研究所近期还与香港金融管理局、泰国中央银行、阿拉伯联合酋长国中央银行联合发起多边央行数字货币桥研究项目，探索央行数字货币在跨境支付中的应用。",
          img: news_1,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    addActive($event) {
      $event.currentTarget.className = "news-items";
    },
    removeActive($event) {
      $event.currentTarget.className = "news-item";
    },
    toProductDetailsOne(data) {
      this.$router.push({
        path: "/ProductDetails",
        query: {
          name: data.name,
          img: data.img,
          describe: data.describe,
          date: data.date,
        },
      });
    },
    toProductDetailsTwo(data) {
      this.$router.push({
        path: "/ProductDetails",
        query: {
          name: data.name,
          img: data.img,
          describe: data.describe,
          date: data.date,
        },
      });
    },
    toServiceDetails(data) {
      this.$router.push({
        path: "/ServiceDetails",
        query: {
          name: data.name,
          img: data.detailsImg,
          describe: data.describe,
          date: data.date,
        },
      });
    },
    toNewDetails(data) {
      this.$router.push({
        path: "/NewsDetails",
        query: {
          name: data.title,
          img: data.img,
          describe: data.describe,
          date: data.year + "-" + data.date,
        },
      });
    },
    toAbout() {
      this.$router.push({
        path: "/About",
      });
    },
  },
  created: function () {},
};
</script>
<style lang="scss" scoped>
@import "../assets/css/index.css";
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  width: 100%;
}
.service-describe {
  font-size: 13px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  text-align: left;
}
</style>